import React, {useContext, useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ChangePasswordContainer from "./changePassword/ChangePasswordContainer";
import LoginContainer from "../../login/LoginContainer";
import Dialog from "rc-dialog";

import { showModal } from "../../../utils/consts";
import eventBus from "../../../utils/eventBus";

import { AppContext } from "../../../App";

import { StyledAccountSettingsWrapper } from "../styledAccount";
import { StyledContentWrapper } from "../../styles/styledContainer";
import { generatePath } from "../../../utils/getLanguage";
import { useBetween } from "use-between";
import BurgerStates from "../../elements/mobileNavigation/BurgerStates";
import TwoFaModal from "../../elements/twoFa/TwoFaModal";
import ForgotPassword from "../../forgotPassword/ForgotPassword";

const AccountSettingsWrapper = ({ isNavigation = false }) => {
  const { t } = useTranslation('siteOptions');
  const { user } = useContext(AppContext);

  const [visible, setVisible] = useState("");

  const { authenticated, twoFaIsActive, setTwoFa } = useContext(AppContext);

  const {
    handleClearAll
  } = useBetween(BurgerStates);

  const renderModal = () => {
    switch (visible) {
      case showModal.CHANGE_PASSWORD:
        return <ChangePasswordContainer/>
      case showModal.FORGOT_PASSWORD:
        return <ForgotPassword setVisible={setVisible}/>
      case showModal.LOGIN:
        return <LoginContainer visible={visible} setVisible={setVisible}/>
      case showModal.CONFIG_SECURITY:
        return <TwoFaModal visible={visible} setVisible={setVisible} twoFaIsActive={twoFaIsActive} setTwoFa={setTwoFa}/>
    }
  };

  return (
    <>
      <Dialog
        visible={visible}
        wrapClassName="default-modal-wrapper"
        onClose={() => setVisible(false)}
        animation="zoom"
        maskAnimation="fade"
        title={visible === showModal.CHANGE_PASSWORD ? t('changePassword') : visible === showModal.LOGIN ? t('enterToAcc') : visible === showModal.CONFIG_SECURITY ? (twoFaIsActive ? t('disable2FA') : t('enable2FA')) : t('forgotYourPasswordTitle')}
        forceRender={false}
        className="default-modal"
        style={{margin: "auto"}}
      >
        {renderModal()}
      </Dialog>

      <StyledContentWrapper isNavigation={isNavigation}>
        <StyledAccountSettingsWrapper>
          {!authenticated ?
            <p className="account-setting" onClick={() => setVisible(showModal.LOGIN)}>{t('clickToEnter')}</p> :
            <ul className="account-setting">
              {isNavigation ? <li>{isNavigation}</li> : ""}
              <li>
                <button
                  type="button"
                  onClick={() => setVisible(showModal.CHANGE_PASSWORD)}
                >
                  {t('changePassword')}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setVisible(showModal.CONFIG_SECURITY)}
                >
                  {twoFaIsActive ? t('disable2FA') : t('enable2FA')}
                </button>
              </li>
              <li>
                <NavLink
                  to={generatePath('/transaction')}
                  onClick={handleClearAll}
                  className="link-transaction">
                  {t('transactions')}
                </NavLink>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => eventBus.dispatch("logout")}
                >
                  {t('exit')}
                </button>
              </li>
            </ul>
          }
        </StyledAccountSettingsWrapper>
      </StyledContentWrapper>
    </>
  );
};

export default AccountSettingsWrapper;