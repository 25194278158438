import React, { useEffect, useState } from "react";
import {Trans, useTranslation} from "react-i18next";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { responseStatus } from "../../../utils/consts";
import InputGroup from "../inputGroup/InputGroup";
import AlertMessage from "../alert/Alert";
import Select, { Option } from "rc-select";
import { StyledSelect, StyledSelectLabel } from "../../styles/styledSelect";
import { StyledFlexAlign, StyledHintText } from "../inputGroup/styledInputGroup";
import {StyledDepositBonus, StyledHelpElement, StyledModalLoader, StyledQrCode} from "./styledPaymentInvoice";
import loadingGif from "../../../assets/images/loading.gif";
import Maintenance from "../maintenance/Maintenance";
import QRCode from "qrcode.react";
import {useBetween} from "use-between";
import {VisibleLeftSidebarStates} from "../leftSidebar/VisibleLeftSidebarStates";
import {closableNotification} from "../notification/ClosableNotification";
import {formatNumber} from "../../../utils/formatNumber";

const PaymentInvoice = ({ paymentMethod, selectPaymentMethod, getPaymentMethod, setVisible }) => {
  const { t } = useTranslation("siteOptions");

  const [paymentData, setPaymentData] = useState({
    loading: true,
    invoice: null,
    methods: [],
    maintenance: !paymentMethod.enabled,
  });
  const [authenticatorSecret, setAuthenticatorSecret] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethod);

  const { setRulesBonusVisible } = useBetween(VisibleLeftSidebarStates);

  // const getAuthenticatorSecretUser = () => {
  //   axios.get("/api/users/authenticator-secret/" + user.userId, userAuthenticationConfig()).then(response => {
  //     if (response.status === responseStatus.HTTP_OK) {
  //       setAuthenticatorSecret(response.data);
  //     }
  //   }).catch(error => {
  //     closableNotification(error.response.data.error, "error");
  //   });
  // };
  //
  // const createPaymentInvoice = () => {
  //   axios.post("/api/user-wallets", {
  //     paymentMethod: selectedPaymentMethod
  //   }, userAuthenticationConfig()).then(response => {
  //     if (response.status === responseStatus.HTTP_CREATED) {
  //       setPaymentInvoice(response.data);
  //       setError(null);
  //
  //       if (!paymentMethod.userWallets) {
  //         getPaymentMethod();
  //         selectPaymentMethod({ ...paymentMethod, userWallets: response.data });
  //       }
  //     }
  //   }).catch(error => {
  //     setError(error.response.data.error);
  //   });
  // };
  //
  const getPaymentMethodsSystem = () => {
    axios.get(`/api/payment-methods?currency.asset=${selectedPaymentMethod?.currency.asset}`,
      userAuthenticationConfig()).
      then(response => {
        if (response.status === responseStatus.HTTP_OK) {
          setPaymentData({
            loading: false,
            methods: response.data["hydra:member"],
            invoice: response.data["hydra:member"][0]?.userWallet,
            confirmations: response.data["hydra:member"][0]?.confirmations,
            bonus: response.data["hydra:member"][0]?.bonus,
            maintenance: response.data["hydra:member"][0]?.enabled,
          })
          setSelectedPaymentMethod(response.data["hydra:member"].find((method) => method.id === paymentMethod.id));
          getPaymentInvoiceData(response.data["hydra:member"].find((method) => method.id === paymentMethod.id));
        }
      }).
      catch(error => {
        setPaymentData((prev) => {
          return {
            ...prev,
            loading: false,
          }
        })
        closableNotification(error.response.data.error, "error");
      });
  };

  const getPaymentInvoiceData = (newPaymentMethods) => {
    setPaymentData((prev) => {
      return {
        ...prev,
        invoice: null,
        loading: true
      }
    })

    axios.get(`/api/payment/invoice-data/${newPaymentMethods.id}`, userAuthenticationConfig())
      .then(response => {
        if (response.status === responseStatus.HTTP_OK) {
          if (!paymentMethod.userWallets) {
            getPaymentMethod();
            selectPaymentMethod({ ...paymentMethod, userWallets: response.data });
            return;
          }
          setPaymentData({
            loading: false,
            methods: response.data?.paymentMethods,
            invoice: response.data?.userWallet,
            confirmations: response.data?.confirmations,
            bonus: response.data?.bonus,
          })
          setAuthenticatorSecret(response.data?.tempQrSecret);
        }
      })
      .catch(error => {
        setPaymentData((prev) => {
          return {
            ...prev,
            loading: false,
            maintenance: true
          }
        })
      })
  };

  useEffect(() => {
    getPaymentMethodsSystem();
  }, []);

  const handleChangePaymentSystem = (value) => {
    const newPaymentMethods = paymentData.methods.find((paymentMethod) => paymentMethod.id === value);
    setSelectedPaymentMethod(newPaymentMethods);
    if (!newPaymentMethods.enabled) {
      setPaymentData((prev) => {
        return {
          ...prev,
          maintenance: true,
          invoice: null,
        }
      })
      return
    }
    window.scrollTo(0, 0);
    setAuthenticatorSecret(false);
    getPaymentInvoiceData(newPaymentMethods);
  };

  function copyText (e) {
    navigator.clipboard.writeText(e.target.value).then(() => {
      closableNotification(t(e.target.name  === "wallet" ? "copyWalletNotificationSuccess" : "copyMemoNotificationSuccess"), "info")
    }).catch((err) => {
      closableNotification(t("copyReferralLinkNotificationError"), "error")
    });
  }

  if (paymentData.loading) {
    return <StyledModalLoader>
      <img src={loadingGif} alt={'loading...'}/>
    </StyledModalLoader>;
  }

  return (
    <div id="payment-invoice">
      {paymentData.invoice?.value?.address ? <InputGroup
        autocomplete="off"
        id="wallet"
        type="text"
        label={t("yourPersonal") + " " + selectedPaymentMethod.currency.name + " (" +
          selectedPaymentMethod.paymentSystem.name + ") " +
          t("address")}
        name="wallet"
        value={paymentData.invoice?.value?.address ?? ''}
        readonly
        onClick={(e) => copyText(e)}
        style={{cursor: "pointer"}}
      /> : <InputGroup
        autocomplete="off"
        id="wallet"
        type="text"
        label={t("yourPersonal") + " " + selectedPaymentMethod.currency.name + " (" +
          selectedPaymentMethod.paymentSystem.name + ") " +
          t("address")}
        style={{ textAlign: 'center' }}
        name="wallet"
        value={t('Wallet Maintenance')}
      />}

      {paymentData.invoice ?
        <>
          {paymentData.invoice.value?.tag && <InputGroup
            autocomplete="off"
            id="memo"
            type="text"
            label={t("memoLabelInput")}
            name="memo"
            value={paymentData.invoice.value?.tag ?? ''}
            readonly
            style={{cursor: "pointer"}}
            onClick={(e) => copyText(e)}
          />}
          <StyledFlexAlign mb="15">
            <StyledHintText>{t('minimumAmount')}: {selectedPaymentMethod?.paymentFee?.min} {selectedPaymentMethod.currency.asset}</StyledHintText>
            <StyledHintText>{t('commission')}: {Number(0).toFixed(8)} {selectedPaymentMethod.currency.asset}</StyledHintText>
          </StyledFlexAlign>
          <StyledQrCode>
            <div style={{padding: "5px", backgroundColor: "white"}}>
              <QRCode
                  size={140}
                  bgColor="white"
                  fgColor="#000"
                  className="flow-data__qrcode"
                  style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
                  value={paymentData.invoice?.value?.address ?? ''}
              />
            </div>
          </StyledQrCode>
        </> :
        null
      }
      {(!paymentData.invoice || paymentData.maintenance) && <Maintenance
          message={t('technicalMaintenance')}
      />}
      {paymentData.methods && paymentData.methods.length > 0 ?
        <>
          <StyledSelect style={{position: "relative"}} id={"select-payment"}>
            <StyledSelectLabel style={{ fontSize: 16 }}>{t('chooseNetwork')}:</StyledSelectLabel>
            <Select
              getPopupContainer={() => document.getElementById("select-payment")}
              className="custom-select"
              name="category"
              value={selectedPaymentMethod?.id}
              defaultValue={selectedPaymentMethod?.id}
              onChange={(value) => handleChangePaymentSystem(value)}
              virtual={false}
              dropdownAlign={{offset: [-1, 12]}}
            >
              {paymentData.methods.map((value, key) => (
                <Option
                  value={value.id}
                  key={key}
                >
                  <div className="option-select-item">
                    {value?.name_view}
                  </div>
                </Option>
              ))}
            </Select>
          </StyledSelect>
          {(paymentData.invoice && !paymentData.maintenance) && <StyledDepositBonus>
            {paymentData && paymentData?.bonus?.deposit?.percent && paymentData?.bonus?.min &&
            <p>
              <Trans
                  i18nKey="siteOptions:depositBonusInfoInPayment"
                  values={{ percent: paymentData?.bonus?.deposit?.percent, count: formatNumber(paymentData?.bonus?.min), currency: selectedPaymentMethod.currency.asset }}
                  components={{ span: <span /> }}
              />
            </p>}
            <div>
              <StyledHelpElement onClick={() => {
                setRulesBonusVisible(true);
                setVisible(false);
              }}>?</StyledHelpElement>
            </div>
          </StyledDepositBonus> }
          {
            !!paymentData.invoice && <AlertMessage
              message={<div>
                <div>{t("makeSurePaymentSystem", { name: selectedPaymentMethod.currency.name })}</div>
                <div>{t("transactionConfirmations", { confirmations: paymentData?.confirmations?.confirmationsAmount })}</div>
              </div>}
              mt="15"
              type={"dotted"}
            />
          }
        </> :
        null
      }
      {(!paymentData.invoice || paymentData.maintenance) && <AlertMessage message={t('paymentMaintenance', {
        wallet: selectedPaymentMethod.currency.name
      })} mt={30}/>}
    </div>
  );
};

export default PaymentInvoice;